import React from 'react';
import './index.css';
import { Button, InputGroup, Input } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { inputToCurrency, currencyToFloat } from './HelperFunctions.js'
import { reactLocalStorage } from 'reactjs-localstorage';

class FlatRate extends React.Component {
	constructor(props) {
		super(props);

		this.state =
		{
			showFlatRateInput: false,
			flatRateAmount: 0.0,
			flatRateAmountDisplayValue: "$0.00",
			placeHolderText: "$0.00",
			firstTimeInput: true,
			dropdownOpen: false,
			frequencyValue: 1,
			frequenceValueDisplay: "1",
			frequencyText: "Enter how many times this fee will occur on a yearly basis (Enter 12 if the payment is due monthly, 1 for yearly payment.)"
		};
		
		this.onChangeInput = this.onChangeInput.bind(this);
		this.onChangeFrequencyInput = this.onChangeFrequencyInput.bind(this);
		this.onFocus = this.onFocus.bind(this);
	}

	componentDidMount(){
		if(Object.keys(reactLocalStorage.getObject(this.props.cn)).length === 0){
			reactLocalStorage.setObject(this.props.cn,{ "flatRateAmount" : this.state.flatRateAmount, 'latRateAmountDisplayValue': this.state.latRateAmountDisplayValue,})
		}else{
			var fra = reactLocalStorage.getObject(this.props.cn)
			this.state.flatRateAmount = fra['flatRateAmount']
			this.state.flatRateAmountDisplayValue = fra['latRateAmountDisplayValue']
		}
	}
	
	onChangeInput(value) {
		var number = value.replace(/,/g, '').replace('$', '');
		if (!(/^\d*\.?\d*$/.test(number))) {
			return false;
		} else {
			if (number < 0) {
				return false;
			} else {
				this.setState({ flatRateAmount: currencyToFloat(value), flatRateAmountDisplayValue: inputToCurrency(value) },function(){
					let latRateAmountDisplayValue;
					this.props.showFrequencyInput && this.state.showFlatRateInput ? 
						latRateAmountDisplayValue = `${inputToCurrency(value)} x ${this.state.frequencyValue} = $${this.state.flatRateAmount * this.state.frequencyValue}`
						: latRateAmountDisplayValue = inputToCurrency(value);
					reactLocalStorage.setObject(this.props.cn , { 'flatRateAmount' : currencyToFloat(value) , 'latRateAmountDisplayValue': latRateAmountDisplayValue})
				});
			}
		}
	}

	onChangeFrequencyInput(value) {
		if (!(/^\d*\.?\d*/.test(value))) {
			return false;
		} else {
		if (value < 1) {
			return false;
		} else {
				this.setState({ frequencyValue: value },function(){
					reactLocalStorage.setObject(this.props.cn , { 'flatRateAmount' : currencyToFloat(value) })
				});
			}
		}
	}

	onFocus() {
		if (this.state.firstTimeInput) {
			this.setState({ flatRateAmountDisplayValue: "$" });
			//eslint-disable-next-line
			this.state.firstTimeInput = false;
		}
	}

	onFrequencyFocus(value) {
		if(!value)
			this.setState({ frequencyValue: 0 });
	}

	getFlatRateAmount(){
		let flatRateAmount;
		if(this.props.showFrequencyInput && this.state.showFlatRateInput)
			flatRateAmount = this.state.flatRateAmount * this.state.frequencyValue;
		else
			flatRateAmount = this.state.flatRateAmount;

		return flatRateAmount;
	}

	render() {
		return (
			<div>
				<div>
					<h2>{this.props.questionText}</h2>
					<Button size="lg" className="yes" color="success" onClick={(e) => this.setState({ showFlatRateInput: true })}>Yes</Button>
					<Button style={{ width:'65.03px'}} size="lg" className="no" color="primary" onClick={(e) => this.props.updateParentState(0.0, this.props.isOneTimeFee, this.props.whatRendersNext ,true,this.props.localState)}>No</Button>
					{ !this.props.notDisplayBack &&
						<Button style={{ float:'right' }} size="lg" className="back" outline color="primary" onClick={(e) => this.props.backComponent(this.props.isFirstIntegration,this.props.whatRendersBack, this.props.cn)}>Back</Button>
					}
				</div>
				<br />
				<div>
				{
					this.props.showFrequencyInput && this.state.showFlatRateInput ?
						<div style={{ paddingBottom:"1.5em", paddingTop:"1.5em"}}>
							<div style={{fontSize: "12px"}}> {this.state.frequencyText}</div>
							<Input value={this.state.frequencyValue} type="number" onClick={(evt) => { this.onFrequencyFocus(evt.target.value) }}  onChange={(evt) => this.onChangeFrequencyInput(evt.target.value)} />
						</div>
						: null
				}
				{
					this.state.showFlatRateInput ?
						<div>
							{this.props.clarifyingInstructions != null ? <div style={{fontSize: "12px"}}> {this.props.clarifyingInstructions}</div> : null}
							<InputGroup>
								<Input value={this.state.flatRateAmountDisplayValue} type="text" onClick={(evt) => { this.onFocus() }} onChange={(evt) => this.onChangeInput(evt.target.value)} />
							</InputGroup>
							<br />
							<div style={{ float: "right" }}>
								<Button size="lg" className="next" outline color="test" onClick={(e) => this.props.updateParentState(this.getFlatRateAmount(), this.props.isOneTimeFee, this.props.whatRendersNext)}>Next</Button>
							</div>
						</div>
						: null
				}
				</div>
			</div>
		);
	}
}


export default FlatRate;